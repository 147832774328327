import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl";

import ContactOverview from "../components/contactOverview";
import { title } from "process";

const GetInTouch = ({}) => {
  const data = useStaticQuery(graphql`
    query getintouch {
      sanityGetInTouch {
        id
        title {
          de
          en
        }
        copy {
          de
          en
        }
        mainphone
        mainemail
        instagramurl
        linkedinurl
        contactLocations {
          _key
          location
          phone
          address1
          address2
          googlemapslink
        }
      }
    }
  `)
  const intl = useIntl()
  const content = data.sanityGetInTouch
  const instagramUrl = content.instagramurl || null;
  const linkedinUrl = content.linkedinurl || null;
  
  return (
    <div
      className="relative pt-20 pb-16 tro-default-padding-x bg-brand"
      id="contactAnchor"
    >
      
      <div className="tro-grid-responsive">
        <div className="font-sans text-lg font-black col-span-full medium:text-xl large:text-2xl medium:col-span-4">
          {content.title[intl.locale]}
        </div>
        
        <div className="pb-16 font-sans text-sm col-span-full medium:col-span-8 large:col-span-6 medium:text-lg large:text-xl">
          <div className="pb-10">
            {content.copy[intl.locale]}
          </div>
          <div>
            <div className="w-full grid grid-cols-6 gap-x-4 gap-y-0 medium:gap-x-8">
              <div className="col-span-3">
                <a href={`tel:${content.mainphone}`} className="inline-block border-white pb-0.5 border-b-5 hover:border-b-3 transition duration-300 ease-in-out hover:border-black">
                  {content.mainphone}
                </a>
              </div>
              <div className="col-span-3">
                <a href={`mailto:${content.mainemail}`} className="inline-block border-white pb-0.5 border-b-5 hover:border-b-3 transition duration-300 ease-in-out hover:border-black">
                  {content.mainemail}
                </a>
              </div>
            </div>
          </div>
          {(instagramUrl || linkedinUrl) && (
            <div className="mt-8">
              <div className="w-full grid grid-cols-6 gap-x-4 gap-y-0 medium:gap-x-8">
                {instagramUrl && (
                  <div className="col-span-3">
                    <a href={ instagramUrl } target="_blank" className="inline-block border-white pb-0.5 border-b-5 hover:border-b-3 transition duration-300 ease-in-out hover:border-black">
                      Instagram
                    </a>
                  </div>
                )}
                {linkedinUrl && (
                  <div className="col-span-3">
                    <a href={ linkedinUrl } target="_blank" className="inline-block border-white pb-0.5 border-b-5 hover:border-b-3 transition duration-300 ease-in-out hover:border-black">
                      LinkedIn
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      
      <ContactOverview locations={content.contactLocations} />
      
    </div>
  );
};

export default GetInTouch;