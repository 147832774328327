import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, Link, changeLocale, IntlContextConsumer, } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";
import moment from "moment";
import GetInTouch from "../components/getInTouch";

import LogoWhite from "../assets/images/svg/logo-white.svg";
import CloseButton from "../assets/images/svg/closebutton.svg";

export default function Layout({ customClass = undefined, title, description = null, mainClassName, textWhite = false, noIndex = false, children, og_image = null, path = null, slugs = null }) {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [scrollDir, setScrollDir] = useState("down");
  const [isScrollMenu, setIsScrollMenu] = useState(false);
  const data = useStaticQuery(graphql`
    query siteSettings {
      sanitySiteSettings(_id: {eq: "siteSettings"}) {
        _id
        title
        description {
          de
          en
        }
        sharepic {
          asset {
            url
          }
        }
      }
    }
  `)
  
  const intl = useIntl()

  const content = data.sanitySiteSettings

  let sharepic = content.sharepic && content.sharepic.asset && content.sharepic.asset.url ? content.sharepic.asset.url : false;
  if (og_image) {
    sharepic = og_image;
  }

  const pagesList = [
    { slug: "/projects", title: "Projects" },
    { slug: "/services", title: "Services" },
    { slug: "/insights", title: "Insights" },
    { slug: "/about-us", title: "About Us" },
    { slug: "#contactAnchor", title: "Contact" },
  ];

  const metaPagesList = [
    { slug: "/terms", title: "Terms & Conditions" },
    { slug: "/legal", title: "Legal" },
    { slug: "/privacy", title: "Privacy" },
  ];

  const currentYear = moment().format("YYYY");

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    const scrollBgDistance = 1000;
    const elBgBold = document.querySelector('main.bg-bold');
    let ticking = false;
    
    const updateScrollDir = () => {
      const { scrollY } = window;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;

      if (scrollDir === "down" && scrollY > 150) {
        setIsMenuVisible(false);
      }
      if (scrollDir === "up") {
        setIsMenuVisible(true);
      }

      if (scrollY > 250) {
        setIsScrollMenu(true);
      } else {
        setIsScrollMenu(false);
      }
    };

    const onScroll = () => {
      if (elBgBold && lastScrollY < scrollBgDistance) {
        const newSize = 94 * (1 - (lastScrollY / scrollBgDistance));
        elBgBold.style.transition = `none`;
        elBgBold.style.backgroundSize = `${newSize}vw auto`;
      }
      
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () =>
      window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);
  
  const switchLanguage = (e, language) => {
    e.preventDefault()
    changeLocale(language)
  }

  function getUrlForLocale(locale) {
    const pathParts = path.split('/').slice(2);

    if (!slugs) {
      return `${process.env.GATSBY_DOMAIN}${locale}/${pathParts.join('/')}`;
    }

    const pathPartsWithoutSlug = pathParts.slice(0, -1);
    return `${process.env.GATSBY_DOMAIN}${locale}/${pathPartsWithoutSlug.join('/')}/${slugs[locale].current}`;
  }

  return (
    <div className={`tro-layout-default ${customClass ? customClass : ''}`}>
      <Helmet
        title={`${title ? `${title} • ${content.title}` : content.title}`}
        defer={false}
        htmlAttributes={{ lang : intl.locale }}
      >
        <meta name="description" content={ description ? description : content.description[intl.locale] } />
        <meta property="og:description" content={ description ? description : content.description[intl.locale] } />

        {path && (
          <link rel="alternate" hreflang="de" href={getUrlForLocale('de')}></link>
        )}
        {path && (
          <link rel="alternate" hreflang="en" href={getUrlForLocale('en')}></link>
        )}

        <meta property="og:type" content="Website" />
        <meta property="og:title" content={`${title ? `${title} • ${content.title}` : content.title}`} />
        
        {sharepic && (
          <meta name="og:image" content={ sharepic } />
        )}
        
        {sharepic && (
          <meta property="og:image" content={ sharepic } />
        )}
        
        {noIndex && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <div
        className={`fixed top-0 left-0 z-50 flex items-center justify-between w-screen transition transform duration-300 ease-out tro-default-padding-x ${
          isMenuVisible ? "" : "-translate-y-40"
        } ${isScrollMenu ? "py-1 h-14 bg-white" : "py-3 medium:py-6"}`}
      >
        <Link to="/" className={`tro-main-logo ${textWhite && !isMobileMenuVisible ? 'border-white' : 'border-black'} ${ isScrollMenu ? 'tro-main-logo__scroll' : 'tro-main-logo__default' } border-4 flex justify-center items-center transition-all duration-100`}>
          <svg width="69" height="24" viewBox="0 0 69 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6555 1.09338H0.945312V4.81041H8.30631V23.3956H12.2699V4.81041H19.6309V1.09338H19.6555ZM56.7067 4.4436C61.0396 4.4436 64.5601 7.94054 64.5601 12.2445C64.5601 16.5484 61.0396 20.0453 56.7067 20.0453C52.3739 20.0453 48.8534 16.5484 48.8534 12.2445C48.8534 7.94054 52.3739 4.4436 56.7067 4.4436ZM56.7067 0.726562C50.3059 0.726562 45.1113 5.88639 45.1113 12.2445C45.1113 18.6025 50.3059 23.7624 56.7067 23.7624C63.1076 23.7624 68.3022 18.6025 68.3022 12.2445C68.3022 5.88639 63.1076 0.726562 56.7067 0.726562ZM41.1231 23.3956L35.6085 14.0296C36.4209 13.7606 37.1349 13.3694 37.7996 12.7825C39.1782 11.5598 40.0891 9.7746 40.0891 7.79381C40.0891 5.81303 39.1782 4.00342 37.7996 2.80516C36.1255 1.36237 33.9837 1.11783 31.4972 1.11783H24.6039V23.42H28.4198V14.4942C29.5523 14.4942 30.8078 14.4942 31.5464 14.4942L36.741 23.42H41.1231V23.3956ZM28.4198 4.81041H32.1126C34.2052 4.81041 34.8207 5.17722 35.2638 5.56849C35.9039 6.13093 36.2732 6.93792 36.2732 7.79381C36.2732 8.64971 35.9285 9.43224 35.2638 10.0191C34.8207 10.4104 34.2298 10.7772 32.1619 10.7772H28.4198V4.81041Z" 
            fill={`${textWhite && !isMobileMenuVisible ? 'white' : 'black'}`}
            className="transition-all duration-100" />
          </svg>
        </Link>
        <nav className="tro-main-nav">
          <ul
            className={`bg-white text-lg tablet:text-special tablet:bg-transparent tablet:relative hidden tablet:flex space-y-0 tablet:space-x-11`}
          >
            {pagesList.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.slug}
                  activeClassName="tro-menu-item__active"
                  partiallyActive={true}
                  className={`relative font-mono uppercase border-b-0 border-brand-transparent hover:border-brand hover:border-b-3 ${textWhite && !isScrollMenu ? 'text-white hover:text-gray-200' : 'hover:text-gray-700'} transition-all duration-300 ease-out`}
                >
                  <svg
                    width="148"
                    height="148"
                    viewBox="0 0 148 148"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute opacity-0 w-2.5 h-2.5 top-0.5 -left-5 tablet:-left-4 tablet:top-0.5"
                  >
                    <rect
                      x="36.7696"
                      y="74"
                      width="52.388"
                      height="52.388"
                      transform="rotate(-45 36.7696 74)"
                      stroke="#EBEE00"
                      strokeWidth="52"
                    />
                  </svg>
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}

            <IntlContextConsumer>
              {({ languages, language: currentLocale }) =>
                languages.map(language => {
                  if (language != currentLocale) {
                    return (
                      <li key={language}>
                        <a href={`/`} onClick={e => switchLanguage(e, language)} className={`pointer relative font-mono uppercase border-b-0 border-brand-transparent hover:border-brand hover:border-b-3 ${textWhite ? 'text-white hover:text-gray-200' : 'hover:text-gray-700'} transition-all duration-300 ease-out`}>
                          {language.toUpperCase()}
                        </a>
                      </li>
                    )
                  }
                })
              }
            </IntlContextConsumer>
            
          </ul>
          <div
            className={`flex items-center font-mono text-xs uppercase cursor-pointer tablet:hidden ${
              isMobileMenuVisible ? "" : "space-x-3"
            }`}
          >
            <span className={`${textWhite && !isScrollMenu && !isMobileMenuVisible ? 'text-white' : ''}`}>{isMobileMenuVisible ? "Close" : "Menu"}</span>
            <div
              onClick={() => {
                setIsMobileMenuVisible(!isMobileMenuVisible);
              }}
            >
              {isMobileMenuVisible ? <CloseButton /> : (
                <svg width="40" height="22" viewBox="0 0 40 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line y1="1" x2="40" y2="1" stroke={`${textWhite && !isScrollMenu && !isMobileMenuVisible ? 'white' : 'black'}`} strokeWidth="2"/>
                  <line y1="11" x2="40" y2="11" stroke={`${textWhite && !isScrollMenu && !isMobileMenuVisible ? 'white' : 'black'}`} strokeWidth="2"/>
                  <line y1="21" x2="40" y2="21" stroke={`${textWhite && !isScrollMenu && !isMobileMenuVisible ? 'white' : 'black'}`} strokeWidth="2"/>
                </svg>
              )}
            </div>
          </div>
        </nav>
      </div>

      <div className={`fixed pt-10 z-40 top-0 flex justify-center items-center w-full h-screen bg-brand transition-all duration-500 ease-in-out table:hidden ${
        isMobileMenuVisible ? 'left-0' : 'left-full'}`}>
        <ul
          className={`text-lg space-y-0`}
        >
          {pagesList.map((item, index) => (
            <li key={index} className="ml-2">
              <Link
                to={item.slug}
                activeClassName="tro-menu-item__active"
                partiallyActive={true}
                className="relative block pb-3 font-sans font-black"
              >
                <svg
                  width="148"
                  height="148"
                  viewBox="0 0 148 148"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute opacity-0 w-2.5 h-2.5 top-1.5 -left-5"
                >
                  <rect
                    x="36.7696"
                    y="74"
                    width="52.388"
                    height="52.388"
                    transform="rotate(-45 36.7696 74)"
                    stroke="#FFFFFF"
                    strokeWidth="52"
                  />
                </svg>
                <span className="inline-block pt-0.5">{item.title}</span>
              </Link>
            </li>
          ))}

          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.map(language => {
                if (language != currentLocale) {
                  return (
                    <li key={language} className="ml-2">
                      <a href={`/`} onClick={e => switchLanguage(e, language)} className="block font-sans">
                        {language.toUpperCase()}
                      </a>
                    </li>
                  )
                }
              })
            }
          </IntlContextConsumer>
        </ul>
      </div>
      
      <div className="overflow-x-hidden tro-page-content">
        <main className={`tro-main ${mainClassName ? mainClassName : ''}`}>
          <div className="tro-main-content">
            {children}
          </div>
        </main>
        
        <GetInTouch />
        
        <footer className="pb-16 tro-default-padding-x bg-brand-semiblack pt-14">
          <div className="tro-grid-responsive">
            <div className="col-span-3 medium:col-span-4">
              <LogoWhite />
              
              <nav className="mt-12">
                <ul className="opacity-40">
                  <li className="font-mono text-xs text-white">&copy;&nbsp;{currentYear} TRO</li>
                  {metaPagesList.map((item, index) => (
                    <li key={index} className="mt-2.5">
                      <Link
                        to={item.slug}
                        className="font-mono text-xs text-white uppercase hover:opacity-70 transition-all duration-150 ease-out"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                  <li className="mt-2.5">
                    <a href="#" id="open_preferences_center" className="font-mono text-xs text-white uppercase hover:opacity-70 transition-all duration-150 ease-out">Cookies</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-span-3 medium:col-span-8">
              <nav className="mt-4">
                <ul className="block w-full">
                {pagesList.map((item, index) => (
                  <li key={index} className="block w-full mb-3">
                    <Link
                      to={item.slug}
                      activeClassName="tro-footer-link__active"
                      className="flex items-center font-sans text-base text-white tro-footer-link medium:text-4xl hover:opacity-70 transition-all duration-150 ease-out whitespace-nowrap"
                    >
                      <svg
                        width="148"
                        height="148"
                        viewBox="0 0 148 148"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3 mb-1 mr-2 opacity-0 medium:w-5 medium:h-5 medium:mb-2 medium:mr-3"
                      >
                        <rect
                          x="36.7696"
                          y="74"
                          width="52.388"
                          height="52.388"
                          transform="rotate(-45 36.7696 74)"
                          stroke="#EBEE00"
                          strokeWidth="52"
                        />
                      </svg>
                      <span>{item.title}</span>
                    </Link>
                  </li>
                ))}
                </ul>
              </nav>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
