import * as React from "react";

import ArrowRight from "../assets/images/svg/arrow-right.svg";

const ContactOverview = ({locations = []}) => {
  return (
    <div className="tro-grid-responsive">
      {locations.map((item, index) => (
        <div
          key={index}
          className="flex flex-col justify-between relative p-3 mb-5 bg-white border-black text-2xs medium:text-xs large:mb-0 col-span-6 small:col-span-2 medium:col-span-4 border-l-6"
        >
          
          <div className="font-sans font-black uppercase text-2xs small:text-xs medium:text-sm mb-8">
            {item.location}
          </div>
          <div className="">
            {item.phone && (
              <a href={`tel:${item.phone}`} className="hover:text-gray-800 transition-colors duration-100">{item.phone}</a>
            )}
            {item.address1 && (
              <div>{item.address1}</div>
            )}
            {item.address2 && (
              <div>{item.address2}</div>
            )}
            {item.googlemapslink && (
              <div><a href={item.googlemapslink} target="_blank" className="hover:text-gray-800 transition-colors duration-100">Google Maps &rarr;</a></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactOverview;
